<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="formSubmitted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          !this.record ? $t("MF.diagnoses.new") : $t("MF.diagnoses.update")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="mdDiagnosesForm" v-model="valid">
          <!--<v-autocomplete
            v-model="mdDiagnosesForm.illness"
            clearable
            outlined
            :items="itemTypes"
            :loading="itemTypesLoaded"
            :filter="typeFilter"
            :rules="[v => !!v || $t('MF.diagnoses.validation.type')]"
            :label="$t('MF.diagnoses.form.type')"
            item-text="name"
            item-value="id"
            return-object
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              {{ data.item.name }}
            </template>
          </v-autocomplete>-->
          <v-autocomplete
            v-model="mdDiagnosesForm.icd"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            clearable
            outlined
            hide-selected
            :filter="icdFilter"
            item-text="Code"
            item-value="API"
            :rules="[v => !!v || $t('MF.diagnoses.validation.type')]"
            :label="$t('MF.diagnoses.form.type')"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Write the ICD code or diagnoses
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.Code }} <br />
              {{ item.Description }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.Code }} <br />
              {{ item.Description }}
            </template>
          </v-autocomplete>

          <v-textarea
            name="entry_body"
            outlined
            :label="$t('MF.diagnoses.form.details')"
            auto-grow
            v-model="mdDiagnosesForm.examination"
            :rules="examinationRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t("AUTH.GENERAL.CANCEL_BUTTON") }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="formSubmitted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t("APPOINTMENTS.add.booking") }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <v-form ref="mdDiagnosesForm" v-model="valid">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <v-autocomplete
            v-model="mdDiagnosesForm.icd"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            clearable
            outlined
            hide-selected
            :filter="icdFilter"
            item-text="Code"
            item-value="API"
            :rules="[v => !!v || $t('MF.diagnoses.validation.type')]"
            :label="$t('MF.diagnoses.form.type')"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Write the ICD code or diagnoses
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.Code }} <br />
              {{ item.Description }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.Code }} <br />
              {{ item.Description }}
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <v-textarea
          name="entry_body"
          outlined
          :label="$t('MF.diagnoses.form.details')"
          auto-grow
          v-model="mdDiagnosesForm.examination"
          :rules="examinationRules"
        ></v-textarea>
      </div>

      <v-btn color="primary mb-3" @click="submit" :disabled="!valid">{{
        $t("AUTH.GENERAL.SUBMIT_BUTTON")
      }}</v-btn>
    </v-form>
    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t("AUTH.GENERAL.CANCEL_BUTTON") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "DiagnosesForm",
  props: ["mfid", "dialog", "record"],
  //components: {Avatar},
  data() {
    return {
      error: false,
      errorMsg: "",
      itemTypes: [],
      itemTypesLoaded: true,
      valid: false,

      // dialog: false,
      formSubmitted: false,
      examinationRules: [
        v => !!v || this.$t("MF.diagnoses.validation.details"),
        v => v.trim().length >= 10 || this.$t("MF.diagnoses.validation.length")
      ],
      mdDiagnosesForm: {
        examination: "",
        icd: null,
        via: this.$root.viaid
        // medical_file: this.mfid,
      },
      date: new Date().toISOString().substr(0, 10),
      body: "",
      entries: [],
      isLoading: false,

      search: null
    };
  },
  watch: {
    search(val) {
      //don't start yet
      // console.log("val", val);
      if (val != null && val.length < 3) return;
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      ApiService.getICD(val, val)
        .then(data => {
          if (data.data.diagnosticsname != "") this.entries = data.data;
          // console.log("data0", data.data);
        })
        .catch(error => {
          this.error = error;
          this.snackbar = true;
        })
        .finally(() => (this.isLoading = false));
    }
  },
  computed: {
    items() {
      return this.entries.map(entry => {
        const Description = entry.diagnosticsname;
        const Code = entry.diagnosticscode;

        return Object.assign({}, { Code, Description });
      });
    }
  },
  mounted() {
    //this.getItemTypes();
    this.record &&
      Object.assign(this.mdDiagnosesForm, {
        icd: this.record["icd"],
        examination: this.record["examination"]
      });
    //this.getICD()
  },
  methods: {
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "illnesses?fields=id,name,symptoms"
        );
        this.itemTypes = data.data;
        this.itemTypesLoaded = false;
      } catch (error) {
        this.error = error;
        // console.log(error);
      }
    },
    typeFilter(item, queryText) {
      return item.name.toLowerCase().includes(queryText.toLowerCase());
    },
    icdFilter(item, queryText) {
      return (
        item.Code.toLowerCase().includes(queryText.toLowerCase()) ||
        item.Description.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    async submit() {
      try {
        // console.log("viaid", this.$root.viaid)
        if (this.$refs.mdDiagnosesForm.validate()) {
          this.formSubmitted = true;
          if (this.record) {
            await ApiService.update(
              "items/md_diagnoses",
              this.record.id,
              this.mdDiagnosesForm
            );
          } else {
            this.mdDiagnosesForm["medical_file"] = this.mfid;
            await ApiService.post(
              "items/md_diagnoses?fields=created_on",
              this.mdDiagnosesForm
            );
          }

          this.formSubmitted = false;
          this.$refs.mdDiagnosesForm.reset();

          this.$emit(
            "backToStepOneAndToast",
            this.record
              ? "Diagnoses Added Successfully"
              : "Diagnoses Updated Successfully"
          );
        } else {
          this.errorMsg = this.$t("MF.diagnoses.error");
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.errorMsg = error;
        // console.log(error);
      }
    },
    close() {
      this.$emit("closeClicked");
    }
  }
};
</script>
